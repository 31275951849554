import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';
import router from '@/router';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
function createP(data) {
  return storeRequest.post('/products', {
    product: data,
  });
}
function get(params) {
  return storeRequest.get('/printhub/mockups', {
    params: { ...params },
  });
}
function getOption(id) {
  return storeRequest.get('/printhub/options/' + id);
}
function getMockupById(id) {
  return storeRequest.get('/printhub/mockups/' + id);
}
function getMockupType() {
  return storeRequest.get('/printhub/mockup-types');
}

function getArtwork(params) {
  return storeRequest.get('/printhub/artworks', {
    params: { ...params },
  });
}
function createArtwork(data) {
  return storeRequest.post('/printhub/artworks', {
    artwork: data,
  });
}
function deleteArtwork(item) {
  console.log('deleteArtwork', item._id);
  return storeRequest.delete('/printhub/artworks/' + item._id);
}
function getCampaign(params) {
  return storeRequest.get('/printhub/campaigns?order=-createAt', {
    params: { ...params },
  });
}
function createCampaign(data) {
  return storeRequest.post('/printhub/campaigns', data);
}
function deleteCampaign(id) {
  return storeRequest.delete('/printhub/campaigns/' + id);
}
function getCampaignById(id) {
  return storeRequest.get('/printhub/campaigns/' + id);
}
function updateCampaign(id, data) {
  return storeRequest.put('/printhub/campaigns/' + id, data);
}
function uploadMultiFileImage(id, data) {
  return storeRequest.post('/printhub/campaigns/' + id + '/clone', {
    data: data,
    launch: 1,
  });
}
function previewMockup(idMockup, options) {
  // {
  //   layout:'front',
  //   imageSrc:'',
  //   color:'',
  //   left:'',
  //   top:''
  // }
  console.log(`options`, options);
  return storeRequest.get(`printhub/mockups/${idMockup}/preview`, {
    params: { ...options },
  });
}

function previewMockupV2(idMockup, options) {
  // {
  //   layout:'front',
  //   imageSrc:'',
  //   color:'',
  //   left:'',
  //   top:''
  // }
  console.log(`options`, options);
  return storeRequest.get(`printhub/templates/${idMockup}/preview`, {
    params: { ...options },
  });
}

function getTemplateByIdMockup(id) {
  return storeRequest.get(`/printhub/mockups/${id}/templates`);
}
export const printHubApi = {
  get,
  getMockupType,
  getArtwork,
  createArtwork,
  deleteArtwork,
  createCampaign,
  getCampaignById,
  updateCampaign,
  getCampaign,
  uploadMultiFileImage,
  deleteCampaign,
  getMockupById,
  createP,
  previewMockup,
  getOption,
  getTemplateByIdMockup,
  previewMockupV2,
};
