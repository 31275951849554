<template>
  <v-row>
    <v-col cols="2" class="px-5 py-2">
      <v-card outlined class="pa-1">
        <v-img
          :lazy-src="imageHelpers.url(item.imageSrc, { width: 100, height: 100 })"
          max-height="300"
          max-width="300"
          :src="imageHelpers.url(item.imageSrc, { width: 100, height: 100 })"
          alt=""
          srcset=""
        ></v-img>
      </v-card>
    </v-col>
    <v-col cols="3" class="d-flex align-center catalog-title">
      {{ item.name }}
    </v-col>
    <v-col cols="4" class="d-flex align-center catalog-title" v-if="item.isUpload">
      {{ item.name }}
    </v-col>
    <v-col cols="4" class="d-flex align-center color-fail" color="red" v-if="!item.isUpload">
      Sorry, your artwork dimension must be 2000 x 2000px at least. Please try again!
    </v-col>
    <v-col cols="2" class="d-flex align-center catalog-title" v-if="item.isUpload">
      <v-icon color="green">
        fas fa-check-circle
      </v-icon>
    </v-col>
    <v-col cols="2" class="d-flex align-center catalog-title color-fail" v-if="!item.isUpload">
      Failed
    </v-col>
    <v-col cols="1" class="d-flex align-center">
      <v-btn icon color="grey" @click="deleteDesign(item._id)">
        <v-icon size="16">fas fa-trash-alt</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import imageHelpers from '@/helpers/image';
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    deleteDesign: {
      type: Function,
      default(id) {},
    },
  },
  data() {
    return {
      imageHelpers,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.catalog-title {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 13px;
}
.color-fail {
  color: red;
}
</style>
