<template>
  <div class="product-page px-6">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto py-1" outlined>
          <v-card-text class="py-0">
            <v-col cols="12" class="subtitle-1 font-weight-bold py-1">
              Want to save time and lower your fulfillment cost?
            </v-col>
            <v-col cols="12">
              <v-btn outlined color="primary" class="mr-3">
                Enable auto fulfill with Lattehub
              </v-btn>
              <span>We will process and print your orders with mapped products 24 hours after orders are placed</span>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="pt-2">
        <v-btn
          color="grey"
          class="px-0 btn-view-details"
          text
          @click="$router.push({ name: 'website.apps.print-hub.campaigns' })"
        >
          <i class="fas fa-caret-left mr-2"></i>{{ $t('CAMPAIGN') }}
        </v-btn>
      </v-col>
      <v-col cols="12" class="page-header d-flex py-0 align-center">
        <span class="page-title">{{ $t('Bulk duplicate tool') }}</span>
        <v-spacer></v-spacer>
        <v-col class="d-flex align-center">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="uploadImages" :disabled="!allowUpload">{{ $t('Launch campaigns') }}</v-btn>
        </v-col>
      </v-col>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title class="font-weight-bold subtitle-1 py-3">
            Original Campaign
          </v-card-title>
          <v-divider></v-divider>
          <v-row class="pa-3">
            <v-col cols="2">
              <v-card class="pa-3" v-if="!loadingCampaign">
                <!-- <v-img
                  class="artwork-image"
                  alt=""
                  src="https://pbimg.xpdcdn.com/pbase/shop-id/10249842/artworks/0x400@feaf5691df04ab2f8f913fc4fdba961d.png"
                ></v-img> -->
                <v-img
                  :lazy-src="imageHelpers.url(campaign.designs[0].imageSrc, { width: 500, height: 500 })"
                  max-height="300"
                  max-width="300"
                  :src="imageHelpers.url(campaign.designs[0].imageSrc, { width: 500, height: 500 })"
                  alt=""
                  srcset=""
                ></v-img>
              </v-card>
              <v-skeleton-loader v-if="loadingCampaign" width="100%" type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="7" class="font-weight-bold subtitle-2 d-flex align-center">{{ campaign.title }}</v-col>
            <v-col cols="3" class="d-flex align-center justify-end">
              <v-btn text color="blue" class="btn-view-details">{{ $t('Views details') }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" class="pt-5">
        <v-card outlined>
          <v-card-title class="font-weight-bold subtitle-1 py-3 d-flex align-center">
            <div class="col-6">Upload design files</div>
            <input
              type="file"
              class="hidden"
              accept="image/png"
              @change="onChangeImage"
              ref="upload"
              color="red"
              multiple
            />
            <div class="col-6 d-flex align-center justify-end" v-if="listFile && listFile.length > 0">
              <v-btn outlined color="black" @click="inputArtwork">Upload files</v-btn>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-row class="pa-3">
            <v-col cols="12" class="py-0">
              You can upload up to 50 design files each time. Every file you upload will be added as its own duplicate
              campaign. The file name will become the campaign title. Please note that your design filesshould be
              similar in dimensions to make sure launched campaigns will be identical
            </v-col>
            <v-col cols-12 v-if="!isLoading">
              <v-card outlined class="col-12 pa-0" v-if="listFile && listFile.length === 0">
                <v-card-title class="px-0 py-0">
                  <v-row class="pa-5 py-1 pb-2">
                    <v-col cols="3 d-flex align-center justify-between">
                      <v-icon size="16" class="mr-3">fas fa-file</v-icon>
                      <div>
                        <div class="font-weight-bold subtitle-2">50 Files</div>
                        <div class="caption" color="grey">Maximum upload</div>
                      </div>
                    </v-col>
                    <v-col cols="3 d-flex align-center justify-between">
                      <v-icon size="16" class="mr-3">fas fa-file-image</v-icon>
                      <div>
                        <div class="font-weight-bold subtitle-2">.PNG</div>
                        <div class="caption" color="grey">File supported</div>
                      </div>
                    </v-col>
                    <v-col cols="3 d-flex align-center justify-between">
                      <v-icon size="16" class="mr-3">fas fa-th></v-icon>
                      <div>
                        <div class="font-weight-bold subtitle-2">150 DPI</div>
                        <div class="caption" color="grey">Minimum</div>
                      </div>
                    </v-col>
                    <v-col cols="3 d-flex align-center justify-between">
                      <v-icon size="16" class="mr-3">fas fa-lock</v-icon>
                      <div>
                        <div class="font-weight-bold subtitle-2">80 MB</div>
                        <div class="caption" color="grey">Maximum file size</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text class="upload pa-0 d-flex align-center justify-center py-16">
                  <v-btn color="primary" @click="inputArtwork">
                    Upload Files
                  </v-btn>
                </v-card-text>
              </v-card>
              <v-card outlined class="col-12 pa-0" v-if="listFile && listFile.length > 0 && !first">
                <v-card-title class="px-0 py-0">
                  <v-row>
                    <v-col cols="3" class="offset-2 subtitle-2 font-weight-bold">File name</v-col>
                    <v-col cols="4" class="subtitle-2 font-weight-bold">Campaign title</v-col>
                    <v-col cols="2" class="subtitle-2 font-weight-bold">Status</v-col>
                    <v-col cols="1" class="subtitle-2 font-weight-bold">Action</v-col>
                  </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <BulkDuplicateTable :listFile="listFile" :deleteDesign="deleteDesign" />
                </v-card-text>
              </v-card>
            </v-col>
            <v-overlay :value="isLoading">
              <div class="fz-18">
                Loading Image....
                <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
              </div>
            </v-overlay>
            <!-- <v-col cols="12" >
              Loading
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import imageHelpers from '@/helpers/image';
import { uploadService } from '@/apis/upload.s';
import axios from 'axios';
import BulkDuplicateTable from './BulkDuplicateTable';
import { printHubApi } from '@/apis/printHub';
import event from '@/plugins/event-bus';

export default {
  components: { BulkDuplicateTable },
  data() {
    return {
      isLoadingSubmit: false,
      isLoadingImage: false,
      imageHelpers,
      listFile: [],
      totalUpload: 0,
      isLoading: false,
      campaign: {},
      loadingCampaign: true,
      first: true,
      allowUpload: false,
    };
  },
  methods: {
    inputArtwork() {
      // console.log(this.$refs.artwork);
      this.$refs.upload.click();
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },
    hex(value) {
      return Math.floor(value).toString(16);
    },
    async onChangeImage(e) {
      console.log('length', e.target.files.length);
      this.isLoadingImage = true;
      this.totalUpload = e.target.files.length;
      this.isLoading = true;
      let size = { width: 0, height: 0 };
      let inputTypes = ['image/png'];
      var _URL = window.URL || window.webkitURL;

      let img = new Image();
      if (this.totalUpload > 0 && this.totalUpload <= 50) {
        for (let index = 0; index < this.totalUpload; index++) {
          let sizeAllow = false;
          var objectUrl = _URL.createObjectURL(e.target.files[index]);
          img.src = _URL.createObjectURL(e.target.files[index]);
          img.onload = function() {
            size.width = this.width;
            size.height = this.height;
          };
          let bool = true;
          let sizeImage = e.target.files[index].size / 1048576;
          console.log('size', sizeImage);
          if (bool) {
            if (inputTypes.includes(e.target.files[index].type) && sizeImage < 80) {
              let file = e.target.files[index];
              console.log('file', file);
              try {
                let type = file.name.split('.')[file.name.split('.').length - 1];
                let id = this.objectId();
                let urlData = await uploadService.get({ filename: `lattehub-image-${id}.${type}` });
                if (urlData) {
                  let res = await axios.put(urlData.data.presignedUrl, file, {
                    headers: {
                      'Content-Type': `${file.type}`,
                    },
                  });
                  let obj = {
                    name: file.name,
                    alt_text: file.name,
                    imageSrc: urlData.data.imagePath,
                    _id: id,
                    size: size,
                  };
                  if (size.width < 1500 || size.height < 1500) {
                    obj.isUpload = false;
                  } else {
                    obj.isUpload = true;
                  }
                  this.image = obj;
                  this.listFile.unshift(obj);
                }
              } catch (error) {
                this.$store.commit('setMessages', {
                  messages: 'Error image ',
                  type: 'error',
                });
              }
            } else {
              this.$store.commit('setMessages', {
                messages: 'Not allow type ',
                type: 'error',
              });
            }
          } else {
            this.$store.commit('setMessages', {
              messages: 'Sorry, your artwork dimension must be 1500 x 1500px at least. Please try again!',
              type: 'error',
            });
          }
        }
      }
      this.isLoading = false;
      this.totalUpload = 0;
      this.first = false;
      if (this.listFile.find(file => !file.isUpload)) {
        this.allowUpload = false;
      } else {
        this.allowUpload = true;
      }
      this.isLoadingImage = false;
    },
    async uploadImages() {
      if (this.allowUpload) {
        this.listFile.map(file => {
          return {
            title: file.name,
            layout: 'front',
            imageSrc: file.imageSrc,
          };
        });
        let data = this.listFile.map(it => {
          return {
            imageSrc: it.imageSrc,
            title: it.name.split('.')[0],
            layout: 'front',
          };
        });
        printHubApi.uploadMultiFileImage(this.$route.params.id, data);
        // event.$emit('success-api', ['Đang chạy test']);
        setTimeout(() => {
          this.listFile = [];

          this.$router.push(`/admin/app/catalog-product/campaigns`);
        }, 200);
      }
    },
    deleteDesign(id) {
      let index = this.listFile.findIndex(item => item._id === id);
      if (index > -1) {
        this.listFile.splice(index, 1);
      }
      if (this.listFile.find(file => !file.isUpload)) {
        this.allowUpload = false;
      } else {
        this.allowUpload = true;
      }
    },
  },
  async created() {
    this.loadingCampaign = true;
    let res = await printHubApi.getCampaignById(this.$route.params.id);
    this.campaign = res.data.campaign;
    this.loadingCampaign = false;
  },
};
</script>

<style lang="scss" scoped>
.btn-view-details {
  &:hover {
    text-decoration: underline;
  }
}
.upload {
  border: 1px dashed grey;
  border-radius: 5px;
}
.hidden {
  display: none;
}
.v-messages {
  display: none !important;
}
</style>
