<template>
  <div>
    <div v-for="item in listFile" :key="item._id">
      <BulkDuplicateItem :item="item" :deleteDesign="deleteDesign" />
    </div>
  </div>
</template>

<script>
import BulkDuplicateItem from './BulkDuplicateItem';
export default {
  components: { BulkDuplicateItem },
  props: {
    listFile: {
      type: Array,
      default: () => [],
    },
    deleteDesign: {
      type: Function,
      default(id) {},
    },
  },
};
</script>

<style></style>
